import React, { useEffect } from 'react'
import SideBar from '../componets/SideBar'
import { Outlet } from 'react-router-dom'
import Navbar from '../componets/Navbar'
import CreateProfile from './CreateProfile'

const Home = () => {
 
  return (
    <>
      <div className='flex  '>

        <SideBar />

        <div className="md:pl-[20%] pl-[70px] flex flex-col h-full   w-full ">
          <div className="h-16">
            <Navbar />
          </div>
         <Outlet /> 
        

        </div>



      </div>



    </>
  )
}

export default Home