import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ProtectedRoutes = ({ children }) => {
    const navigate = useNavigate();
  
    const getAccessToken = () => {
      const accesstoken = localStorage.getItem("accessToken");
      if (accesstoken) {
        return accesstoken;
      }
      return "";
    };
  
    useEffect(() => {
      let token = getAccessToken();
      if (!token) {
        navigate("/login");
      }
    }, [navigate]);
  
    return children;
  };
export default ProtectedRoutes