import React, { useEffect, useState } from 'react'
import { LoginData } from '../dataSource/LoginData'
import { InputField } from './CreateProfile'
import { Link, useNavigate } from 'react-router-dom'
import usePost from '../hook/usePost'
import LoaderComponent from '../componets/LoaderComponent'
import toast, { Toaster } from 'react-hot-toast';
import { useLocalStorage } from 'use-hooks'
const Login = () => {
  const [formData, setFormData] = useState({
    username: "",
    password: ""

  })
  const [accessToken, setaccessToken] = useLocalStorage("accessToken", "");
  const navigate = useNavigate()
  const successToast = (msg) => toast.success(msg)
  const errorToast = (msg) => toast.error(msg);

  const { makeRequest, data, isLoading, error, statusCode } = usePost("api/v1/user/login")
 

  const getAccessToken = () => {
    const accesstoken = localStorage.getItem("accessToken");
    if (accesstoken) {
      return accesstoken;
    }
    return "";
  };

  
  useEffect(() => {
    const token = getAccessToken()
    console.log("toknss", token)
    if (token) {
      navigate("/user")
    }
  }, [])
  const handlechange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let { username, password } = formData
    if (!username || !password) {
      errorToast("input data is missing")
    } else {
      await makeRequest(formData);
      setFormData({
        username: "",
        password: "",
      })
    }

  }

  useEffect(()=>{
  if(statusCode === 200){
     setaccessToken(data.accessToken)
    navigate("/user")
  }
  
  },[statusCode ])


  if (isLoading) {
    return (
      <div className='relative bg-[#535457] flex justify-center  items-center h-[100vh] pt-10  '>
        <LoaderComponent />
      </div>
    )
  }

  if (error) {
    return (
      <div className='relative bg-[#535457]  h-[100vh] pt-10  '>
        <p>Error: {error.message}</p>
      </div>
    )

  }


  return (
    <>
      <Toaster

      />
      <div className='relative bg-[#535457]  h-[100vh] flex flex-col items-center justify-center  '>
        <h1 className='w-full text-center  text-semibold font-satoshi text-white '>Login </h1>
        <form onSubmit={handleSubmit} className="w-[50%] mx-auto shadow-lg mt-5 p-3 rounded font-satoshi">
          {
            LoginData?.map((ele) => {
              return (
                <InputField
                  key={ele.id}
                  type={ele.type}
                  label={ele.label}
                  value={formData[ele.name]}
                  name={ele.name}
                  handleChange={handlechange}
                />
              )
            })

          }
          <button type="submit" className="bg-red-400 p-3 rounded w-full text-white transform active:scale-75 transition-transform">Submit</button>
          <span className='text-white  flex justify-center text-sm '>new user? <Link className='text-blue-400 underline ' to="/">singup</Link></span>

        </form>

      </div>
    </>


  )
}

export default Login