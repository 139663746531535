import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
const ConnectAccounts = () => {
    const [link, setlink] = useState("")
    const handleLogout = () => {
        localStorage.removeItem("token")
       
    }
    const getLink = async () => {
     
        try {
            const { data } = await axios.get('http://localhost:8000');
            setlink(data)

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const gettoken = () => {
        const token = localStorage.getItem("token")
        if (token) {
            return token
        }
        return ""
    }

    useEffect(() => {
        let token = gettoken()
        if (token) {
            console.log("token", token)
        } else {
            getLink()
        }


    }, [])

    const login = () => {
        window.FB.login(function (response) {
            if (response.authResponse) {
                console.log('Welcome! Fetching your information.... ');
                console.log(response.authResponse)
                window.FB.api('/me', function (response) {
                    console.log('Good to see you, ' + response.name + '.');
                });
            } else {
                console.log('User cancelled login or did not fully authorize.');
            }
        });
    };



    return (
        <div className='relative bg-[#535457]  h-[90vh] pt-10  '>
            <div className='flex gap-x-4 justify-center '>
                <button className='bg-white text-black font-satoshi p-2 rounded-md' onClick={login}>
                    Login with facebook
                </button>
                {
                    link &&
                    <Link className='bg-white text-black font-satoshi p-2 rounded-md' to={link}>
                        login with google
                    </Link>
                }
                {
                    gettoken() && <button className='bg-white text-black font-satoshi p-2 rounded-md' onClick={handleLogout}>logout via google</button>
                }




            </div>

        </div>

    );
};

export default ConnectAccounts;
