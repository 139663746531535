import { useState, useCallback } from 'react';
import axios from 'axios';

export default function usePost(url) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [statusCode, setStatusCode] = useState(null);

  const makeRequest = useCallback(async (requestData, config = {}) => {
 
    setIsLoading(true);
    setError(null);
    setStatusCode(null);

    try {
       
      const response = await axios.post(url, requestData, config);
      
      setData(response.data);
      setStatusCode(response.status);
    } catch (err) {
      setError(err);
      if (err.response) {
        setStatusCode(err.response.status);
      }
    }

    setIsLoading(false);
  }, [url]);

  return { makeRequest, data, isLoading, error, statusCode };
}
