import React, { useEffect, useState } from 'react'
import {  NavLink } from 'react-router-dom'
import { SidebarData } from '../dataSource/SidebarData'

import { useWindowSize } from 'use-hooks';

const SideBar = () => {

const [click,setClick] = useState(false)
const size = useWindowSize();


useEffect(()=>{

  if (size.width<768){
    setClick(true)
  }else{
    setClick(false)
  }
},[size])

  return (
    <aside  className={`fixed  md:w-1/5 w-[70px] z-40  h-screen  `} >
   <div className="h-full px-3 py-4 bg-[#373A40]">
   <div className="space-y-2 font-medium text-white ">
   
   <div className="p-3">
    logo
   </div>
  
    {
      SidebarData?.map((ele)=>{
        return(
          <NavLink
          key={ele.id}
            to={ele.route}
            className={({ isActive }) =>
              `flex items-center justify-center py-3 px-2 font-satoshi rounded ${isActive ? 'bg-[#686D76] ' : 'bg-[#373A40]'}`
            }
          >
            {({ isActive }) => (
              <div className="flex items-center justify-start gap-x-0 md:gap-x-2 w-full">
                <span className='w-4 h-4'>{ele.icon}</span>
                <span className={`text-white  ${click ?"hidden":"flex"}`}>
                  {ele.title}
                </span>
              </div>
            )}
          </NavLink>
        )
      })
    }
   </div>
   

   </div>
</aside>
  )
}

export default SideBar

