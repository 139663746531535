import React, { useEffect, useState } from 'react'
import { InputField } from './CreateProfile';
import { registerData } from '../dataSource/RegisterData';
import { Link, useNavigate } from 'react-router-dom';
import usePost from '../hook/usePost';
import LoaderComponent from '../componets/LoaderComponent';
import toast, { Toaster } from 'react-hot-toast';

const Register = () => {
  const [formData, setFormData] = useState({
    username: "",
    fullname: "",
    phone_number: "", password: "", company_id: ""

  })
  const navigate = useNavigate()
  const successToast = (msg) => toast.success(msg)
  const errorToast = (msg) => toast.error(msg);

  const { makeRequest, data, isLoading, error, statusCode } = usePost("api/v1/user/register")

  const handlechange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }


  const getAccessToken = () => {
    const accesstoken = localStorage.getItem("accessToken");
    if (accesstoken) {
      return accesstoken;
    }
    return "";
  };


  useEffect(() => {
    const token = getAccessToken()
    console.log("toknss", token)
    if (token) {
      navigate("/user")
    }
  }, [])
  useEffect(() => {
    if (statusCode === 201) {
      navigate("/login")
    }
  }, [statusCode])
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { username, fullname, phone_number, password, company_id } = formData
    if (!username || !fullname || !phone_number || !password || !company_id) {
      errorToast("input data is misssing")
    } else {
      await makeRequest(formData);
      setFormData({
        username: "",
        fullname: "",
        phone_number: "", password: "", company_id: ""
      })
    }

  }


  if (isLoading) {
    return (
      <div className='relative bg-[#535457] flex justify-center  items-center h-[100vh] pt-10  '>
        <LoaderComponent />
      </div>
    )
  }

  if (error) {
    return (
      <div className='relative bg-[#535457]  h-[100vh] pt-10  '>
        <p>Error: {error.message}</p>
      </div>
    )

  }

  return (
    <>
      <Toaster />
      <div className='relative bg-[#535457]  h-[100vh] flex items-center justify-center flex-col '>

        <h1 className='w-full text-center text-2xl uppercase text-semibold font-satoshi text-white '>Register </h1>
        <form onSubmit={handleSubmit} className="w-[50%] mx-auto shadow-xl mt-5 p-3 rounded font-satoshi">
          {
            registerData?.map((ele) => {
              return (
                <InputField
                  key={ele.id}
                  type={ele.type}
                  label={ele.label}
                  value={formData[ele.name]}
                  name={ele.name}
                  handleChange={handlechange}
                />
              )
            })
          }

          <button type="submit" className="bg-red-400 p-3 rounded w-full text-white transform active:scale-75 transition-transform">Submit</button>
          <span className='text-white  flex justify-center text-sm '>Already have account? <Link className='text-blue-400 underline ' to="/login">Login</Link></span>

        </form>

      </div>
    </>

  )
}

export default Register