import React from 'react'



const CalendarView = () => {
  return (
    <div className='relative bg-[#535457]  h-[90vh] pt-10  '>

clender
        </div>
  )
}

export default CalendarView