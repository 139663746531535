import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import PostTabData from '../dataSource/PostTabData'

const CreatePost = () => {
  return (
    <div className='relative bg-[#535457] flex flex-col  h-[90vh] pt-6  '>
      <div className="flex gap-x-3">
        {
          PostTabData?.map((ele) => {
            return (

              <NavLink
              to={ele.route}
              className="flex items-center py-3 px-2 font-satoshi rounded"
              activeClassName="activeNav"
            >
              <div className="flex items-center justify-start gap-x-2 w-full">
                <span className="text-white">{ele.title}</span>
              </div>
            </NavLink>
            )

          })
        }
      </div>

      <div className="flex items-center justify-center mx-auto">
        <Outlet />
      </div>

    </div>

  )
}

export default CreatePost