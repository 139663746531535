
const PostTabData = [
    {
        id:"p1",
        title:"Youtube",
        route:"/create_post/"
    
    },{
        id:"p2",
        title:"Facebook",
        route:"/create_post/facebook"
    },{
        id:"p3",
        title:"Instagram",
        route:"/create_post/Instagram"
    }
    ]
    
    export default PostTabData