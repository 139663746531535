import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'use-hooks';
import usePut from '../hook/usePut';
import LoaderComponent from '../componets/LoaderComponent';

const Auth = () => {
  const [token, setToken] = useLocalStorage("token", "");
  const [expiredTime, setexpiredTime] = useLocalStorage("expired", "");
  const { makeRequest, data, isLoading, error, statusCode } = usePut('/api/v1/user/setSocial_token');
  const navigate = useNavigate();
  const location = useLocation();

  const checkCodeInUrl = () => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    console.log("code", code);
    if (code) {
      const newUrl = window.location.origin + window.location.pathname;
      console.log("newUrl", newUrl);
      window.history.replaceState({}, document.title, newUrl);
      return code;
    }
    return "";
  };

  const getToken = async (code) => {
    try {
      const { data } = await axios.post('/gettoken', { code });
      console.log("tokendata is ",data);
      setToken(data.access_token);
      setexpiredTime(data.expiry_date)
      updateRefrestoken(data.access_token,data.refresh_token)
      navigate("/user/connect_accounts");
    } catch (error) {
      console.error('Error posting data:', error);
    }
  };


  const updateRefrestoken = async(token,rtoken)=>{
    const config = {
      headers: {
        Authorization:`Bearer ${token}`,
      },
    };
    await makeRequest({y_RToken:rtoken},config);
  }

  useEffect(() => {
    let code = checkCodeInUrl();
    if (code) {
      getToken(code);
    }
  }, [location]);

  if(isLoading){
    return(<LoaderComponent/>)
  }


  return (
    <>
      {token ? (
        <Link to="/connect_accounts">Go to Connection</Link>
      ) : (
        <div className='text-black'>Auth</div>
      )}
    </>
  );
};

export default Auth;
