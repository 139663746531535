
const NavbarData = [
{
    id:"n1",
    title:"Home",
    route:"/"

},{
    id:"n2",
    title:"Conatct Us",
    route:"/user/contact"
}
]

export default NavbarData