import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import CreatePost from './pages/CreatePost';
import ConnectAccounts from './pages/ConnectAccounts';
import Dashboard from './pages/Dashboard';
import Transitions from './pages/Transitions';
import Conatct from './pages/Conatct';
import CalendarView from './pages/Calendar';
import Auth from './pages/Auth';
import UploadVideo from './pages/Post/UploadVideo';
import Facebook from './pages/Post/Facebook';
import Instgrams from './pages/Post/Instgrams';
import Login from './pages/Login';
import Register from './pages/Register';
import ProtectedRoutes from './ProtectedRoute';
import CreateProfile from './pages/CreateProfile';



const App = () => {
  const gettoken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      return token;
    }
    return "";
  };

  const checkisTokenExpired = ()=>{

  }
  
  return (
    <Router>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/' element={<Register />} />
        <Route
          path="/user"
          element={
            <ProtectedRoutes>
              <Home />
            </ProtectedRoutes>
          }
        >
          <Route path='auth' element={<Auth />} />
          <Route index element={<Navigate to="/user/create_profile" replace />} />
          <Route path="create_profile" element={<CreateProfile />} />
          <Route path="connect_accounts" element={<ConnectAccounts />} />
          <Route path="create_post" element={<CreatePost />}>
            <Route path="" element={<UploadVideo token={gettoken()} />} />
            <Route path="facebook" element={<Facebook />} />
            <Route path="instagram" element={<Instgrams />} />
          </Route>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="transitions" element={<Transitions />} />
          <Route path="calendar" element={<CalendarView />} />
          <Route path="contact" element={<Conatct />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
