export const LoginData = [
   
    {
       id: "r1",
       type: "text",
       label: "Username",
       name: "username"
    },
    {
       id: "r2",
       type: "password",
       label: "Password",
       name: "password"
    }
 ]
 