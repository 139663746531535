import React from 'react'
import { BallTriangle } from 'react-loader-spinner'

const LoaderComponent = () => {
  return (
    <BallTriangle
          height={500}
          width={300}
          radius={5}
          color="#4fa94d"
          ariaLabel="ball-triangle-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
  )
}

export default LoaderComponent