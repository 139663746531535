import React, { useState } from 'react'
import dataForForm from '../dataSource/Formdata'

const CreateProfile = () => {

    const [socialMediaUrl, setSocialMediaUrls] = useState([])
    const [url, seturl] = useState("")
    const [formData, setFormData] = useState({
        fullName: "",
        description: "",
        phoneno: "",
        imageUrl: "",facebook:"",instagram:"",youtube:""
    })

    const handlechange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData)
    }

    

  
    return (
        <div className='relative bg-[#535457]  h-[90vh] pt-10  '>
            <form onSubmit={handleSubmit} className="max-w-md mx-auto shadow-lg mt-5 p-3 rounded font-satoshi">
                {
                    dataForForm?.map((ele) => {
                        return (
                            <InputField
                                key={ele.id}
                                type={ele.type}
                                label={ele.label}
                                value={formData[ele.name]}
                                name={ele.name}
                                handleChange={handlechange}
                            />
                        )
                    })
                }
            
                <button type="submit" className="bg-red-400 p-3 rounded w-full text-white">Submit</button>
            </form>

        </div>
    )
}

export default CreateProfile

export const InputField = ({ type, label, value, handleChange, name }) => {
    return (
        <>
            <div className="relative z-0 w-full mb-4 group">
                <input type={type} name={name} value={value} onChange={handleChange} className="block py-2.5 px-0 w-full text-sm text-[#F7F9F2] bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600  focus:outline-none focus:ring-0 focus:border-white peer" placeholder=" " />
                <label className="peer-focus:font-medium absolute text-sm text-white duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-white peer-focus:dark:text-white peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">{label}</label>
            </div>

        </>
    )
}