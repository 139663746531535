import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const UploadVideo = ({ token }) => {
    const [videoFile, setVideoFile] = useState(null);
    const [thumbnailFile, setThumbnailFile] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [tags, setTags] = useState('');
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const handleVideoChange = (e) => {
        setVideoFile(e.target.files[0]);
    };

    const handleThumbnailChange = (e) => {
        setThumbnailFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        if (!videoFile) {
            alert('Please select a video file first');
            return;
        }

        setUploading(true);

        const metadata = {
            snippet: {
                title: title,
                description: description,
                tags: tags.split(',').map(tag => tag.trim()),
                categoryId: '22', 
            },
            status: {
                privacyStatus: 'public', 
                embeddable: true, 
                license: 'youtube', 
                publicStatsViewable: true 
            },
        };

        try {
            // Step 1: Create the upload session
            const response = await axios.post(
                'https://www.googleapis.com/upload/youtube/v3/videos?uploadType=resumable&part=snippet,status',
                metadata,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            const uploadUrl = response.headers.location;
            console.log('Upload URL:', uploadUrl);

            // Step 2: Upload the video file
            const uploadResponse = await axios.put(uploadUrl, videoFile, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': videoFile.type,
                    'Content-Length': videoFile.size,
                },
                onUploadProgress: (progressEvent) => {
                    setUploadProgress(
                        Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    );
                },
            });

            const videoId = uploadResponse.data.id;
            console.log('Video ID:', videoId);

            // Step 3: Upload the thumbnail
            if (thumbnailFile) {
                await uploadThumbnail(videoId);
            }

            alert('Video uploaded successfully!');
        } catch (error) {
            console.error('Error uploading video:', error);
            alert('Error uploading video');
        } finally {
            setUploading(false);
            setUploadProgress(0);
        }
    };

    const uploadThumbnail = async (videoId) => {
        const formData = new FormData();
        formData.append('file', thumbnailFile);

        try {
            await axios.post(
                `https://www.googleapis.com/upload/youtube/v3/thumbnails/set?videoId=${videoId}`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            console.log('Thumbnail uploaded successfully');
        } catch (error) {
            console.error('Error uploading thumbnail:', error);
            alert('Error uploading thumbnail');
        }
    };
if(!token){
    return(
        <Link to={"/connect_accounts"}>
            Naigate to connect 
        </Link>
    )
}

    return (
        <div>
            
            <h1>Upload Video to YouTube</h1>

            <div className="max-w-md mx-auto shadow-lg mt-5 p-3 rounded font-satoshi">
                <div className="relative z-0 w-full mb-4 group">

                    <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} className="block py-2.5 px-0 w-full text-sm text-[#F7F9F2] bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600  focus:outline-none focus:ring-0 focus:border-white peer" placeholder=" " />

                    <label className="peer-focus:font-medium absolute text-sm text-white duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-white peer-focus:dark:text-white peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Title</label>
                </div>
                <div className="relative z-0 w-full mb-4 group">

                    <input type="text" value={description}
                        onChange={(e) => setDescription(e.target.value)} className="block py-2.5 px-0 w-full text-sm text-[#F7F9F2] bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600  focus:outline-none focus:ring-0 focus:border-white peer" placeholder=" " />
                    <label className="peer-focus:font-medium absolute text-sm text-white duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-white peer-focus:dark:text-white peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Description</label>
                </div>

                <div className="relative z-0 w-full mb-4 group">

                    <input type="text" value={tags}
                        onChange={(e) => setTags(e.target.value)} className="block py-2.5 px-0 w-full text-sm text-[#F7F9F2] bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600  focus:outline-none focus:ring-0 focus:border-white peer" placeholder=" " />
                    <label className="peer-focus:font-medium absolute text-sm text-white duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-white peer-focus:dark:text-white peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Tags (comma separated)</label>
                </div>

                <div className="relative z-0 w-full mb-4 group">

                    <input type="file" accept="video/*"
                        onChange={handleVideoChange} className="block py-2.5 px-0 w-full text-sm text-[#F7F9F2] bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600  focus:outline-none focus:ring-0 focus:border-white peer" placeholder=" " />
                    <label className="peer-focus:font-medium absolute text-sm text-white duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-white peer-focus:dark:text-white peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Video</label>
                </div>

                <div className="relative z-0 w-full mb-4 group">

                    <input type="file"  accept="image/*" onChange={handleThumbnailChange} className="block py-2.5 px-0 w-full text-sm text-[#F7F9F2] bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600  focus:outline-none focus:ring-0 focus:border-white peer" placeholder=" " />
                    <label className="peer-focus:font-medium absolute text-sm text-white duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-white peer-focus:dark:text-white peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Thumbnail</label>
                </div>
                
            <button className="bg-red-400 p-3 rounded w-full text-white" onClick={handleUpload} disabled={uploading}>
                {uploading
                    ? `Uploading... (${uploadProgress}%)`
                    : 'Upload Video and Thumbnail'}
            </button>
            </div>


          
          
        </div>
    );
};

export default UploadVideo;
