export const registerData = [
   {
      id: "r5",
      type: "text",
      label: "Company Id",
      name: "company_id"
   },
   {
      id: "r1",
      type: "text",
      label: "Username",
      name: "username"
   },
   {
      id: "r2",
      type: "text",
      label: "Full Name",
      name: "fullname"
   },
   {
      id: "r3",
      type: "number",
      label: "Phone Number",
      name: "phone_number"
   }, {
      id: "r4",
      type: "password",
      label: "Password",
      name: "password"
   }
]
