import React, { useEffect } from 'react'
import NavbarData from '../dataSource/NavbarData'
import { NavLink, useNavigate } from 'react-router-dom'
import { FaPowerOff } from "react-icons/fa";
import usePost from '../hook/usePost';
import LoaderComponent from './LoaderComponent';
const Navbar = () => {

  const navigate = useNavigate()
  const { makeRequest, data, isLoading, error, statusCode } = usePost("/api/v1/user/logout")

useEffect(()=>{
if (statusCode === 200){
  localStorage.removeItem("accessToken")
  navigate("/login")
}
},[statusCode])

  const handleLogout = async () => {
    const token = localStorage.getItem("accessToken")
    const config = {
      headers: {
        Authorization:`Bearer ${token}`,
      },
    };

    await makeRequest({},config); 
 

  }

  if(isLoading){
    return(<LoaderComponent/>)
  }
  return (
    <>

      <nav className="bg-[#373A40]  ">
        <div className="max-w-screen-xl px-4 py-3 mx-auto">
          <div className="flex items-center justify-between">
            <div className="flex flex-row font-medium mt-0 space-x-8 rtl:space-x-reverse text-sm">
              {
                NavbarData?.map((ele) => {
                  return (
                    <NavLink
                      key={ele.id}
                      to={ele.route}
                      className={({ isActive }) =>
                        `flex items-center py-3 px-2 font-satoshi rounded `
                      }
                    >
                      {({ isActive }) => (
                        <div className="flex items-center justify-start gap-x-0 md:gap-x-2 w-full">
                          <span className={`${isActive ? "text-[#c7c6cc]" : "text-white "}`}>
                            {ele.title}
                          </span>
                        </div>
                      )}
                    </NavLink>
                  )
                })
              }






            </div>
            <button onClick={handleLogout} className='flex cursor-pointer items-center gap-x-0 md:gap-x-2 text-white'>
              <FaPowerOff />
            </button>

          </div>

        </div>
      </nav>

    </>
  )
}

export default Navbar