// SidebarData.js
import { HiUserAdd } from "react-icons/hi";
import { MdPostAdd, MdDashboard, MdPayments, MdOutlineAlternateEmail } from "react-icons/md";
import { FaCalendarDays } from "react-icons/fa6";

export const SidebarData = [
  {
    id: "i1",
    icon: <HiUserAdd />,
    title: "Create Profile",
    route: "/user/create_profile"
  },
  {
    id: "i2",
    icon: <MdOutlineAlternateEmail />,
    title: "Connect Accounts",
    route: "/user/connect_accounts"
  },
  {
    id: "i3",
    icon: <MdPostAdd />,
    title: "Create Post",
    route: "/user/create_post"
  },
  {
    id: "i4",
    icon: <MdDashboard />,
    title: "Dashboard",
    route: "/user/dashboard"
  },
  {
    id: "i5",
    icon: <MdPayments />,
    title: "Transitions",
    route: "/user/transitions"
  },
  {
    id: "i6",
    icon: <FaCalendarDays />,
    title: "Calendar",
    route: "/user/calendar"
  }
];
