const dataForForm = [
    {
       id:"f1",
       type:"text",
       label:"Full Name",
       name:"fullName"
    },  
    {
        id:"f2",
         type:"text",
        label:"Description",
        name:"description"
    },
    {
        id:"f3",
        type:"text",
        label:"Image",
        name:"imageUrl"
        
     },
     {
        id:"f4",
        type:"number",
        label:"Phone Numbar",
        name:"phoneno"
     },
     {
      id:"f5",
      type:"text",
      label:"Facebook",
      name:"facebook"
   },  {
      id:"f6",
      type:"text",
      label:"Instagram",
      name:"instagram"
   }, {
      id:"f7",
      type:"text",
      label:"Youtube Channel",
      name:"youtube"
   }
]

export default dataForForm